@font-face {
  font-family: Vazir;
  src: url("../assets/fonts/vazir/Vazir-Regular-FD.eot");
  src: url("../assets/fonts/vazir/Vazir-Regular-FD.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/vazir/Vazir-Regular-FD.woff2") format("woff2"),
    url("../assets/fonts/vazir/Vazir-Regular-FD.woff") format("woff"),
    url("../assets/fonts/vazir/Vazir-Regular-FD.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Vazir;
  src: url("../assets/fonts/vazir/Vazir-Bold-FD.eot");
  src: url("../assets/fonts/vazir/Vazir-Bold-FD.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/vazir/Vazir-Bold-FD.woff2") format("woff2"),
    url("../assets/fonts/vazir/Vazir-Bold-FD.woff") format("woff"),
    url("../assets/fonts/vazir/Vazir-Bold-FD.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Vazir;
  src: url("../assets/fonts/vazir/Vazir-Black-FD.eot");
  src: url("../assets/fonts/vazir/Vazir-Black-FD.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/vazir/Vazir-Black-FD.woff2") format("woff2"),
    url("../assets/fonts/vazir/Vazir-Black-FD.woff") format("woff"),
    url("../assets/fonts/vazir/Vazir-Black-FD.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Vazir;
  src: url("../assets/fonts/vazir/Vazir-Medium-FD.eot");
  src: url("../assets/fonts/vazir/Vazir-Medium-FD.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/vazir/Vazir-Medium-FD.woff2") format("woff2"),
    url("../assets/fonts/vazir/Vazir-Medium-FD.woff") format("woff"),
    url("../assets/fonts/vazir/Vazir-Medium-FD.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Vazir;
  src: url("../assets/fonts/vazir/Vazir-Light-FD.eot");
  src: url("../assets/fonts/vazir/Vazir-Light-FD.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/vazir/Vazir-Light-FD.woff2") format("woff2"),
    url("../assets/fonts/vazir/Vazir-Light-FD.woff") format("woff"),
    url("../assets/fonts/vazir/Vazir-Light-FD.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Vazir;
  src: url("../assets/fonts/vazir/Vazir-Thin-FD.eot");
  src: url("../assets/fonts/vazir/Vazir-Thin-FD.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/vazir/Vazir-Thin-FD.woff2") format("woff2"),
    url("../assets/fonts/vazir/Vazir-Thin-FD.woff") format("woff"),
    url("../assets/fonts/vazir/Vazir-Thin-FD.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

* {
  text-decoration: none;
}

.animated-fade {
  animation: Fade 0.3s alternate-reverse;
}

.animated-infinite {
  animation-iteration-count: infinite;
}

.en-font {
  font-family: Arial, Helvetica, sans-serif !important;
}

@keyframes Fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.grecaptcha-badge {
  visibility: hidden !important;
}

